import React from 'react'
import'./PageNotFound.css'
const PageNotFound = () => {
  return (
    <div className='pagenotfound'>
      <img src='notFound.jpg'  alt='Page NOT FOUND'/>
    </div>
  )
}

export default PageNotFound