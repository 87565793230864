import React from 'react'
import "./Footer.css"
const Footer = () => {
  return (
    <div className='footer'>
      <div> Movie App </div>
      <div>©2021, Movie , Inc. or its affiliate</div>
    </div>
  )
}

export default Footer